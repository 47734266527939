<template>
  <div class="action-wrapper">
    <div v-if="UserInfo?.level === 1" class="admin-controls">
      <div class="result">
        <span>
          Result:
          <span
            :class="{
              'text-lose': controlResult < 2,
              'text-win': controlResult >= 2,
              'text-pending': controlResult >= 10,
            }"
          >
            {{ controlResult }}x
          </span>
        </span>
        <span>
          Stop point:
          <span
            :class="{
              'text-lose': StopPoint < 2,
              'text-win': StopPoint >= 2,
              'text-pending': StopPoint >= 10,
            }"
          >
            {{ StopPoint }}x
          </span>
        </span>
        <span>
          Cashed out:
          <span class="text-win">
            {{ TruncateToDecimals2(totalAmountBetClassic.win, "", 2) }}
          </span>
        </span>
        <span>
          Remaining:
          <span class="text-pending">
            {{ TruncateToDecimals2(totalAmountBetClassic.remain, "", 2) }}
          </span>
        </span>
      </div>
      <div class="pool">
        <div class="d-flex flex-wrap">
          <div class="input-box">
            <div>
              <div>
                <span class="text-win">Pool size:</span>
                {{ TruncateToDecimals2(PoolSize, "", 2) }}
              </div>
              <input
                class="input"
                type="number"
                placeholder="Amount"
                v-model="poolAmount"
              />
            </div>
          </div>
          <div class="buttons">
            <div ref="depositConfirm">
              <button
                class="botan"
                :class="{ wait: deposit }"
                @click="depositConfirm"
              >
                Deposit
              </button>
              <button class="botan confirm" @click="depositPool">
                Confirm
              </button>
            </div>
            <div ref="withdrawConfirm">
              <button
                class="botan"
                :class="{ wait: withdraw }"
                @click="withdrawConfirm"
              >
                Withdraw
              </button>
              <button class="botan confirm" @click="withdrawPool">
                Confirm
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="input-box">
            <div>
              <div>
                <span class="text-pending">Max pool:</span>
                {{ TruncateToDecimals2(MaxPool, "", 2) }}
              </div>
              <input
                class="input"
                type="number"
                placeholder="Set Max"
                v-model="setMaxAmount"
              />
            </div>
          </div>
          <div class="buttons">
            <div ref="setMaxConfirm" class="sideway">
              <button
                class="botan"
                :class="{ wait: max }"
                @click="setMaxConfirm"
              >
                Set Max
              </button>
              <button class="botan confirm" @click="setMax">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <label class="control" for="control">
        <div>Control Value</div>
        <div class="flexit">
          <div class="input-box">
            <input
              class="input"
              v-model="controlsValue"
              type="number"
              id="control"
            />
          </div>
          <button
            @click="setControlValue"
            class="botan"
          >
            Set stop point
          </button>
        </div>
      </label>
      <div class="trend">
        <div>
          <div class="text-lose">1.00x - 1.99x</div>
          <button @click="setControlsTrend('RED')" class="red botan">
            Set
            <span class="text-lose">RED</span>
          </button>
        </div>

        <div>
          <div class="text-win">≥ 2.00x</div>
          <button @click="setControlsTrend('GREEN')" class="green botan">
            Set
            <span class="text-win">GREEN</span>

          </button>
        </div>

        <div>
          <div class="text-pending">≥ 10.00x</div>
          <button @click="setControlsTrend('MOON')" class="moon botan">
            Set
            <span class="text-pending">MOON</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CrashActions",
  props: {
    PoolSize: {
      type: Number,
      default: 0,
    },
    GameStatus: {
      type: String,
      required: true,
    },
    CounterChart: {
      type: Number,
      required: true,
    },
    StopPoint: {
      type: Number,
      default: 0,
    },
    AllUserBet: {
      type: Object,
      required: true,
    },
    MaxPool: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      controlResult: 0,
      controlsValue: 0,
      poolAmount: null,
      setMaxAmount: null,
      deposit: false,
      withdraw: false,
      max: false,
    };
  },
  computed: {
    ...mapGetters({
      User: "userinfo/User",
      UserInfo: "userinfo/UserInfo",
    }),
    AllBetClassic() {
      if (this.AllUserBet.bet_classic) {
        return this.AllUserBet.bet_classic;
      }
      return [];
    },
    totalAmountBetClassic() {
      let total = {
        bet: 0,
        win: 0,
        remain: 0,
      };
      let cashedout = 0;

      this.AllBetClassic.forEach((item) => {
        if (item && item.username !== 'dinhlong' && item.username !== 'phattai68' && item.username !== 'baonam' && item.username !== 'bigwin1' && item.username !== 'hami68' && item.username !== 'locnguyen' && item.username !== 'winwin9' && item.username !== 'xuanvinh' && item.username !== 'phuongthao68') {
          total.bet += item.amountBet;
          total.win += item.amountWin;
          total.remain += item.amountBet;
          if (item.status === "SUCCESS") {
            cashedout += item.amountBet;
          }
        }
      });

      total.remain = total.bet - cashedout;
      return total;
    },
  },
  methods: {
    setControlValue() {
      this.$emit("adminControls", +this.controlsValue);
    },
    setControlsTrend(input) {
      this.$emit("adminControls", input);
    },
    depositPool() {
      this.$emit("depositPool", +this.poolAmount);
      this.deposit = false;
    },
    withdrawPool() {
      this.$emit("withdrawPool", +this.poolAmount);
      this.withdraw = false;
    },

    depositConfirm() {
      this.deposit = !this.deposit;
      window.removeEventListener("click", this.depositOutside);
      window.addEventListener("click", this.depositOutside);
    },
    depositOutside(e) {
      if (!this.$refs.depositConfirm.contains(e.target)) {
        this.deposit = false;
        window.removeEventListener("click", this.depositOutside);
      }
    },

    withdrawConfirm() {
      this.withdraw = !this.withdraw;
      window.removeEventListener("click", this.withdrawOutside);
      window.addEventListener("click", this.withdrawOutside);
    },
    withdrawOutside(e) {
      if (!this.$refs.withdrawConfirm.contains(e.target)) {
        this.withdraw = false;
        window.removeEventListener("click", this.withdrawOutside);
      }
    },

    setMaxConfirm() {
      this.max = !this.max;
      window.removeEventListener("click", this.setMaxOutside);
      window.addEventListener("click", this.setMaxOutside);
    },
    setMaxOutside(e) {
      if (!this.$refs.setMaxConfirm.contains(e.target)) {
        this.max = false;
        window.removeEventListener("click", this.setMaxConfirm);
      }
    },
    setMax() {
      this.$emit("setMaxPool", this.setMaxAmount);
      this.max = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.action-wrapper {
  padding: 10px;
  padding-top: 0px;
  .admin-controls {
    padding-top: 10px;
    .botan {
      padding: 8px 12px;
      margin: 5px 0px;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      background-color: #49527b;
      border-radius: 5px;
    }
    .control {
      font-size: 16px;
      line-height: 1;
      margin-top: 5px;
      .input-box {
        margin: 0;
        width: fit-content;
        padding: 4px;
        display: inline-block;
      }
      .flexit {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .result {
      text-align: center;
      font-size: 20px;
      line-height: 1.05;
      font-weight: 600;
      margin-left: 10px;
      margin-bottom: 10px;
      > span {
        display: inline-block;
        white-space: nowrap;
        padding: 0px 5px;
        font-size: 18px;
      }
    }
    .trend {
      > div {
        display: inline-block;
        margin: 5px;
        font-weight: 600;
      }
    }
  }
  .pool {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    > div {
      gap: 5px;
    }
    .input-box {
      border-radius: 1px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.05;
      padding: 8px;
      margin: 0;
      width: auto;
      display: flex;
      > div {
        white-space: nowrap;
      }
      .input {
        border-radius: 5px;
        width: 100%;
        max-width: 200px;
        &:focus {
          box-shadow: 0px 0px 0px 2px rgb(44, 65, 118);
        }
      }
    }
    .botan {
      border-radius: 5px;
      margin: 0;
      transition: 0.3s;
    }
    .buttons {
      display: flex;
      align-items: center;
      > div {
        position: relative;
        z-index: 2;
        margin-right: 5px;
        .confirm {
          position: absolute;
          left: 0;
          right: 0;
          bottom: calc(100% + 5px);
          transition: 0.3s;
          transform: translateY(calc(100%));
          background: #6abd46;
          opacity: 0;
          z-index: -1;
          pointer-events: none;
        }
        .wait {
          background: #be1f2d;
        }
        .wait + .confirm {
          transform: none;
          opacity: 1;
          pointer-events: all;
        }
        // &.sideway {
        //   .confirm {
        //     transform: translateX(calc(-100%));
        //     bottom: 0;
        //     right: unset;
        //     left: calc(100% + 5px);
        //   }
        //   .wait + .confirm {
        //     transform: none;
        //   }
        // }
      }
    }
  }
}
</style>
