<template>
  <div class="trendBall">
    <button v-if="Show" class="botan close" @click="$emit('togTrend', false)">
      <img src="@/assets/images/icons/close-white.svg" alt="" />
    </button>
    <button v-else class="botan open" @click="$emit('togTrend', true)">
      <span class="gradient-text">TrendBall</span>
    </button>
    <div v-if="Show" class="trendBall-content">
      <!-- Red -->
      <div class="trendBall-item">
        <div class="trendBall-item-header">
          <h3 class="redBear">Red</h3>
          <div class="redBear-item-total">
            <p>
              <span>{{ $t("happy.player") }}</span> {{ totalPlayerBetRed }}
            </p>
            <p class="d-flex align-items-center mb-0">
              <img
                src="~@/assets/images/icons/icon-usd.png"
                alt=""
                class="mr-1 mb-0"
                style="width: 17px !important"
              />
              {{ TruncateToDecimals2(totalAmountBetRed, "", 2) }}
            </p>
          </div>
        </div>
        <div class="trendBall-item-content">
          <p>{{ $t("happy.player") }}</p>
          <p>Wow</p>
        </div>
        <div class="trendBall-bet-list redBear">
          <div
            class="trendBall-bet-item"
            v-for="(bet, idx) in AllBetRed"
            :key="'red' + idx"
          >
            <p>
              {{ bet.username | overflowed }}
            </p>
            <p
              :class="
                StatusBetGreen === 'PENDING'
                  ? 'text-white'
                  : !StatusBetGreen
                  ? 'text-win'
                  : 'text-lose'
              "
            >
              {{ TruncateToDecimals2(+bet.amountBet, "", 2) }}
              ( {{ bet.currency }} )
            </p>
          </div>
        </div>
      </div>
      <!-- Green -->
      <div class="trendBall-item">
        <div class="trendBall-item-header">
          <h3 class="greenBull">Green</h3>
          <div class="greenBull-item-total">
            <p>
              <span>{{ $t("happy.player") }}</span> {{ totalPlayerBetGreen }}
            </p>
            <p>
              <img
                src="~@/assets/images/icons/icon-usd.png"
                alt=""
                class="mr-1 mb-0"
                style="width: 17px !important"
              />
              {{ TruncateToDecimals2(totalAmountBetGreen, "", 2) }}
            </p>
          </div>
        </div>
        <div class="trendBall-item-content">
          <p>{{ $t("happy.player") }}</p>
          <p>Wow</p>
        </div>
        <div class="trendBall-bet-list greenBull">
          <div
            class="trendBall-bet-item"
            v-for="(bet, idx) in AllBetGreen"
            :key="'green' + idx"
          >
            <p>
              {{ bet.username | overflowed }}
            </p>
            <p
              :class="
                StatusBetGreen === 'PENDING'
                  ? 'text-white'
                  : StatusBetGreen
                  ? 'text-win'
                  : 'text-lose'
              "
            >
              {{ TruncateToDecimals2(+bet.amountBet, "", 2) }}
              ( {{ bet.currency }} )
            </p>
          </div>
        </div>
      </div>
      <!-- Moon -->
      <div class="trendBall-item">
        <div class="trendBall-item-header">
          <h3 class="moon">Moon</h3>
          <div class="moon-item-total">
            <p>
              <span>{{ $t("happy.player") }}</span> {{ totalPlayerBetMoon }}
            </p>
            <p>
              <img
                src="~@/assets/images/icons/icon-usd.png"
                alt=""
                class="mr-1 mb-0"
                style="width: 17px !important"
              />
              {{ TruncateToDecimals2(totalAmountBetMoon, "", 2) }}
            </p>
          </div>
        </div>
        <div class="trendBall-item-content">
          <p>{{ $t("happy.player") }}</p>
          <p>Wow</p>
        </div>
        <div class="trendBall-bet-list moon">
          <div
            class="trendBall-bet-item"
            v-for="(bet, idx) in AllBetMoon"
            :key="'green' + idx"
          >
            <p>
              {{ bet.username | overflowed }}
            </p>
            <p
              :class="
                StatusBetMoon === 'PENDING'
                  ? 'text-white'
                  : StatusBetMoon
                  ? 'text-win'
                  : 'text-lose'
              "
            >
              {{ TruncateToDecimals2(+bet.amountBet, "", 2) }}
              ( {{ bet.currency }} )
            </p>
          </div>
        </div>
      </div>
    </div>
    <p
      v-if="
        !(
          (AllBetRed && AllBetRed.length > 0) ||
          (AllBetGreen && AllBetGreen.length > 0) ||
          (AllBetMoon && AllBetMoon.length > 0)
        ) && Show
      "
      class="text-center mt-5 text-white font-weight-bold"
    >
      Nothing here
    </p>
  </div>
</template>

<script>
export default {
  name: "BetListedTrendBallPageView",
  props: {
    AllUserBet: {
      type: Object,
      required: true,
    },
    MessageSocket: {
      type: Object,
    },
    Show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    StatusBetGreen() {
      let status = "PENDING";
      const arrBetGreenFull = this.AllUserBet.bet_green;
      const arrBetGreenWithStatus = arrBetGreenFull.filter(
        (bet) => !bet.amountBet
      );
      if (arrBetGreenWithStatus.length > 0) {
        status = arrBetGreenWithStatus[0].status;
      } else status = "PENDING";
      return status;
    },
    StatusBetRed() {
      let status = "PENDING";
      const arrBetRedFull = this.AllUserBet.bet_red;
      const arrBetRedWithStatus = arrBetRedFull.filter((bet) => !bet.amountBet);
      if (arrBetRedWithStatus.length > 0) {
        status = arrBetRedWithStatus[0].status;
      } else status = "PENDING";
      return status;
    },
    StatusBetMoon() {
      let status = "PENDING";
      const arrBetMoonFull = this.AllUserBet.bet_moon;
      const arrBetMoonWithStatus = arrBetMoonFull.filter(
        (bet) => !bet.amountBet
      );
      if (arrBetMoonWithStatus.length > 0) {
        status = arrBetMoonWithStatus[0].status;
      } else status = "PENDING";
      return status;
    },
    AllBetRed() {
      let arr = [];
      const arrBetRedFull = this.AllUserBet.bet_red;
      arr = arrBetRedFull.filter(
        (bet) => bet.amountBet > 0 && bet.currency !== "DBS" && !bet.isShow
      );
      return arr;
    },
    AllBetGreen() {
      let arr = [];
      const arrBetGreenFull = this.AllUserBet.bet_green;
      arr = arrBetGreenFull.filter(
        (bet) => bet.amountBet > 0 && bet.currency !== "DBS" && !bet.isShow
      );
      return arr;
    },
    AllBetMoon() {
      let arr = [];
      const arrBetMoonFull = this.AllUserBet.bet_moon;
      arr = arrBetMoonFull.filter(
        (bet) => bet.amountBet > 0 && bet.currency !== "DBS" && !bet.isShow
      );
      return arr;
    },
    totalPlayerBetRed() {
      let totalPlayer = 0;
      totalPlayer = this.AllBetRed.length;
      return totalPlayer;
    },
    totalAmountBetRed() {
      let totalBet = 0;
      totalBet = this.AllBetRed.reduce(
        (total, item) => total + item.amountBet,
        0
      );
      return totalBet;
    },
    totalPlayerBetGreen() {
      let totalPlayer = 0;
      totalPlayer = this.AllBetGreen.length;
      return totalPlayer;
    },
    totalAmountBetGreen() {
      let totalBet = 0;
      totalBet = this.AllBetGreen.reduce(
        (total, item) => total + item.amountBet,
        0
      );
      return totalBet;
    },
    totalPlayerBetMoon() {
      let totalPlayer = 0;
      totalPlayer = this.AllBetMoon.length;
      return totalPlayer;
    },
    totalAmountBetMoon() {
      let totalBet = 0;
      totalBet = this.AllBetMoon.reduce(
        (total, item) => total + item.amountBet,
        0
      );
      return totalBet;
    },
  },
  methods: {
    // changeStatusShowMore() {
    //   const myDiv = document.getElementById('trendBall-bet');
    //   if (this.showMore) {
    //     myDiv.style.flexBasis = 'auto';
    //     this.showMore = false;
    //   } else {
    //     myDiv.style.flexBasis = myDiv.offsetHeight + 'px';
    //     this.showMore = true;
    //   }
    //   myDiv.scrollTop = 0;
    // },
  },
  filters: {
    overflowed: (value) => {
      if (!value) return "";
      const preferLen = 8;
      if (typeof value === "string" && value.length >= preferLen) {
        return value.slice(0, preferLen) + "...";
      } else {
        return value;
      }
    },
  },
  watch: {
    AllUserBet: {
      handler() {
        // console.log(newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.trendBall {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #757575 0%, rgba(1, 6, 15, 0.63) 100%);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  .trendBall-content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .trendBall-item {
      .trendBall-item-header {
        text-align: center;
        line-height: 1.05;
        img {
          width: 100px;
          margin-bottom: 5px;
        }
        h3 {
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 5px;
          &.redBear {
            color: #be1f2d;
          }
          &.greenBull {
            color: #24ff00;
          }
          &.moon {
            color: #ffcb12;
          }
        }
        .greenBull-item-total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 3px solid #24ff00;
          background-image: linear-gradient(
            to bottom,
            rgba(93, 160, 0, 0.2),
            rgba(0, 0, 0, 0)
          );
          padding: 5px 10px;
          p {
            font-size: 14px;
            color: #24ff00;
            margin-bottom: 0px;
            span {
              color: #d4dff4;
            }
          }
        }
        .redBear-item-total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 3px solid #be1f2d;
          background-image: linear-gradient(
            to bottom,
            rgba(190, 31, 44, 0.2),
            rgba(0, 0, 0, 0)
          );
          padding: 5px 10px;
          p {
            font-size: 14px;
            color: #24ff00;
            margin-bottom: 0px;
            span {
              color: #d4dff4;
            }
          }
        }
        .moon-item-total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 3px solid #ffcb12;
          background-image: linear-gradient(
            to bottom,
            rgba(190, 190, 31, 0.2),
            rgba(0, 0, 0, 0)
          );
          padding: 5px 10px;
          p {
            font-size: 14px;
            color: #ffcb12;
            margin-bottom: 0px;
            span {
              color: #d4dff4;
            }
          }
        }
      }
      .trendBall-item-content {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        border-bottom: 1px solid #d4dff4;
        line-height: 1.05;
        p {
          margin-bottom: 0px;
          color: #d4dff4;
        }
      }
      .trendBall-bet-list {
        max-height: 170px;
        overflow: auto;
        padding-left: 10px;
        .trendBall-bet-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          p {
            width: 49%;
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: white;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space:nowrap &:nth-child(1) {
              overflow: hidden;
              justify-content: flex-start;
              img {
                width: 25px;
                margin-right: 3px;
              }
            }
            &:nth-child(2) {
              overflow: hidden;
              justify-content: flex-end;
              img {
                width: 15px;
                margin-left: 3px;
              }
            }
          }
        }
        @media (min-width: 992px) {
          max-height: 170px;
          .trendBall-bet-item {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .trendBall-button {
    width: 100%;
    text-align: right;
    margin-top: 20px;
    button {
      background: #1a1a1a;
      color: rgba(49, 49, 49, 0.71);
      img {
        margin-left: 5px;
      }
    }
  }

  .botan.close {
    width: 18px;
    padding: 2px;
    padding-top: 0;
    position: absolute;
    right: 10px;
    top: 0;
    line-height: 1;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  .botan.open {
    font-weight: 600;
    background-color: #49527b;
    padding: 2px;
    @media (min-width: 992px) {
      writing-mode: vertical-rl;
    }
  }
  @media (min-width: 992px) {
    // width: 800px;
  }
}
</style>
