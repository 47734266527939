var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.UserInfo)?_c('div',{staticClass:"chartCrash"},[_c('div',{staticClass:"chart-header",class:{ admin: _vm.UserInfo?.level === 1 }},[(_vm.UserInfo?.level === 1)?_c('div',{staticClass:"balance"},[_vm._m(0),_c('div',{staticClass:"amount"},[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(_vm.PoolSize, "", 2))+" ")])]):_vm._e(),_c('div',{staticClass:"game-history"},[_c('div',{staticClass:"history"},[_c('div',{staticClass:"row"},_vm._l((_vm.BankRoll),function(item){return _c('div',{key:item.hash,staticClass:"col-auto flex-grow-1"},[_c('div',{staticClass:"game"},[_vm._v(" "+_vm._s(`...${item.hash.slice( item.hash.length - 6, item.hash.length )}`)+" ")]),_c('div',{staticClass:"result",class:{
                green: item.payout >= 2,
                orange: item.payout >= 10,
              }},[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(item.payout, "", 2))+_vm._s(item.payout % 1 === 0 ? ".00" : "")+"x ")])])}),0)]),_c('div',{staticClass:"trend"},[_c('button',{staticClass:"botan toggle",class:{ active: _vm.showTrend },on:{"click":function($event){_vm.showTrend = !_vm.showTrend}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/trend.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("happy.trend"))+" ")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showTrend)?_c('div',{staticClass:"trend-table-wrapper"},[_c('div',{staticClass:"trend-head"},[_vm._v(" "+_vm._s(_vm.$t("happy.trend"))+" "),_c('button',{staticClass:"close botan",on:{"click":function($event){_vm.showTrend = false}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/close-white.svg"),"alt":""}})])]),(_vm.HistorySystem && _vm.HistorySystem.length > 0)?_c('div',{staticClass:"trend-table"},_vm._l((_vm.ForTrendTable),function(item,index){return _c('div',{key:index,staticClass:"trend-item",style:({ color: item.color })},[(item.color)?_c('img',{attrs:{"src":require(`@/assets/images/icons/${
                      item.color === 'red'
                        ? 'strawberry'
                        : item.color === 'green'
                        ? 'lime'
                        : 'lemon'
                    }.png`)}}):_vm._e()])}),0):_vm._e()]):_vm._e()])],1)])]),_c('div',{staticClass:"myChart-wrapper"},[(false)?_c('v-chart',{ref:"myChart",attrs:{"option":_vm.chartOptions,"update-options":{ notMerge: false }}}):_vm._e(),_c('div',{staticClass:"countDown-time"},[(_vm.GameStatus === 'PENDING')?_c('p',{staticClass:"gradient-text"},[_vm._v(" "+_vm._s(_vm.$t("happy.startIn"))+" "+_vm._s(_vm.Countdown)+"s ")]):(_vm.GameStatus === 'END')?_c('p',{staticClass:"bang"},[_vm._v(" BANG @"+_vm._s(_vm.TruncateToDecimals2(_vm.CounterChart ? _vm.CounterChart : 0, "", 2))+"x ")]):_c('p',{staticClass:"payout gradient-text"},[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(_vm.CounterChart ? _vm.CounterChart : 0, "", 2))+"x ")]),(false)?_c('div',{staticClass:"ripple"},[_c('img',{staticStyle:{"animation-delay":"600ms"},attrs:{"src":require("@/assets/images/rippleSlice.svg")}}),_c('img',{staticStyle:{"animation-delay":"12000ms"},attrs:{"src":require("@/assets/images/rippleSlice.svg")}}),_c('img',{staticStyle:{"animation-delay":"1800ms"},attrs:{"src":require("@/assets/images/rippleSlice.svg")}}),_c('img',{staticStyle:{"animation-delay":"2400ms"},attrs:{"src":require("@/assets/images/rippleSlice.svg")}}),_c('img',{staticStyle:{"animation-delay":"3000ms"},attrs:{"src":require("@/assets/images/rippleSlice.svg")}}),_c('img',{staticStyle:{"animation":"none"},attrs:{"src":require("@/assets/images/rippleSlice.svg")}})]):_vm._e()])],1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('span',[_vm._v("Pool size")])])
}]

export { render, staticRenderFns }