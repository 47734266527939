<template>
  <div class="historyCrash">
    <h3 class="mb-0">All User Online</h3>
    <div class="historyCrash-content">
      <template v-if="AllUserOnline !== {}">
        <div class="table-responsive" v-if="!showTableMobile">
          <table class="table myTable">
            <thead>
              <tr>
                <th class="text-center">ID</th>
                <th class="text-center">Username</th>
                <th class="text-center">Amount BS</th>
                <th class="text-center">Amount SBS</th>
                <th class="text-center">Amount DBS</th>
                <th class="text-center">Profit ABC</th>
                <th class="text-center">Profit XYZ</th>
                <th class="text-center">For Show</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, key, idx) in AllUserOnline" :key="'all_user_online: ' + idx">
                <template
                  v-if="AllUserOnline[key].USERNAME !== 'dinhlong' && AllUserOnline[key].USERNAME !== 'phattai68' && AllUserOnline[key].USERNAME !== 'baonam' && AllUserOnline[key].USERNAME !== 'bigwin1' && AllUserOnline[key].USERNAME !== 'hami68' && AllUserOnline[key].USERNAME !== 'locnguyen' && AllUserOnline[key].USERNAME !== 'winwin9' && AllUserOnline[key].USERNAME !== 'xuanvinh' && AllUserOnline[key].USERNAME !== 'phuongthao68'">
                  <td class="text-center">{{ key }}</td>
                  <td class="text-center">{{ AllUserOnline[key].USERNAME }}</td>
                  <td class="text-center text-warning font-weight-bold">
                    $
                    {{
                      TruncateToDecimals2(+AllUserOnline[key].BALANCE.BS, '', 4)
                    }}
                  </td>
                  <td class="text-center text-warning font-weight-bold">
                    $
                    {{
                      TruncateToDecimals2(+AllUserOnline[key].BALANCE.SBS, '', 4)
                    }}
                  </td>
                  <td class="text-center text-warning font-weight-bold">
                    {{
                      TruncateToDecimals2(+AllUserOnline[key].BALANCE.DBS, '', 4)
                    }}
                  </td>
                  <td class="text-center font-weight-bold" :class="+AllUserOnline[key].USER_PROFIT < 0
                        ? 'text-danger'
                        : 'text-success'
                      ">
                    $
                    {{
                      TruncateToDecimals2(+AllUserOnline[key].USER_PROFIT, '', 4)
                    }}
                  </td>
                  <td class="text-center font-weight-bold" :class="+AllUserOnline[key].USER_PROFIT_2 < 0
                        ? 'text-danger'
                        : 'text-success'
                      ">
                    $
                    {{
                      TruncateToDecimals2(+AllUserOnline[key].USER_PROFIT_2, '', 4)
                    }}
                  </td>
                  <td class="text-center font-weight-bold">
                    {{
                      AllUserOnline[key].SHOW ? 'Show' : ''
                    }}
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-mobile" v-else>
          <div v-for="(value, key, idx) in AllUserOnline" :key="'playHistory: ' + idx" class="w-100" role="tablist">
            <template
              v-if="AllUserOnline[key].USERNAME !== 'dinhlong' && AllUserOnline[key].USERNAME.username !== 'phattai68' && AllUserOnline[key].USERNAME.username !== 'baonam' && AllUserOnline[key].USERNAME.username !== 'bigwin1' && AllUserOnline[key].USERNAME.username !== 'hami68' && AllUserOnline[key].USERNAME.username !== 'locnguyen' && AllUserOnline[key].USERNAME.username !== 'winwin9' && AllUserOnline[key].USERNAME.username !== 'xuanvinh' && AllUserOnline[key].USERNAME.username !== 'phuongthao68'">
              <b-button v-b-toggle="`playHistory-${idx}`" class="btnNoStyle header" role="tab">{{
                AllUserOnline[key].USERNAME }}</b-button>
              <b-collapse :id="`playHistory-${idx}`" class="mt-2" accordion="my-accordion" role="tabpanel">
                <ul>
                  <li>
                    ID: <span>{{ key }}</span>
                  </li>
                  <li>
                    Username: <span>{{ AllUserOnline[key].USERNAME }}</span>
                  </li>
                  <li>
                    Amount BS:
                    <span class="font-weight-bold text-warning">$
                      {{
                        TruncateToDecimals2(+AllUserOnline[key].BALANCE.BS, '', 4)
                      }}</span>
                  </li>
                  <li>
                    Amount SBS:
                    <span class="font-weight-bold text-warning">
                      $
                      {{
                        TruncateToDecimals2(
                          +AllUserOnline[key].BALANCE.SBS,
                          '',
                          4
                        )
                      }}
                    </span>
                  </li>
                  <li>
                    {{ $t('dashboard.typeBet') }}:
                    <span>
                      {{ history.typeBet }}
                    </span>
                  </li>
                  <li>
                    Amount DBS:
                    <span class="font-weight-bold text-warning">
                      {{
                        TruncateToDecimals2(
                          +AllUserOnline[key].BALANCE.DBS,
                          '',
                          4
                        )
                      }}</span>
                  </li>
                  <li>
                    Profit ABC:
                    <span class="font-weight-bold" :class="+AllUserOnline[key].USER_PROFIT < 0
                          ? 'text-danger'
                          : 'text-success'
                        ">
                      $
                      {{
                        TruncateToDecimals2(
                          +AllUserOnline[key].USER_PROFIT,
                          '',
                          4
                        )
                      }}</span>
                  </li>
                  <li>
                    Profit XYZ:
                    <span class="font-weight-bold" :class="+AllUserOnline[key].USER_PROFIT_2 < 0
                          ? 'text-danger'
                          : 'text-success'
                        ">
                      $
                      {{
                        TruncateToDecimals2(
                          +AllUserOnline[key].USER_PROFIT_2,
                          '',
                          4
                        )
                      }}</span>
                  </li>
                </ul>
              </b-collapse>
            </template>
          </div>
        </div>
      </template>
      <p v-else class="text-center mb-0">No User Online</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HistoryCrashPageView',
  components: {},
  props: {
    AllUserOnline: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      widthL: 0,
      showTableMobile: false,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    checkResizeWindow() {
      this.widthL = window.innerWidth;
    },
  },
  mounted() {
    this.checkResizeWindow();
    this.windowEvent = () => {
      this.checkResizeWindow();
    };
    if (this.widthL <= 576) {
      this.showTableMobile = true;
    } else {
      this.showTableMobile = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.historyCrash {
  padding: 0px 10px;

  @media (min-width: 768px) {
    padding: 0;
  }

  h3 {
    font-size: 16px;
    color: white;
    margin-top: 23px;
  }

  .historyCrash-content {
    background: linear-gradient(180deg, #757575 0%, rgba(1, 6, 15, 0.63) 100%);
    border-radius: 3px;
    padding: 20px 15px;
    margin-top: 20px;
  }

  .currency-icon {
    width: 25px;
    object-fit: contain;
  }
}

.table-mobile {
  width: 100%;
  background: linear-gradient(180deg, #757575 0%, rgba(1, 6, 15, 0.63) 100%);
  border-radius: 4px;
  padding: 20px;

  .header {
    width: 100%;
    background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
    border-radius: 6px;
    padding: 0px 25px;
    min-height: 55px;
    margin-bottom: 20px;
    font-weight: 700;

    &.lost {
      background: linear-gradient(91.46deg, #be1f2d 1.08%, #d7535e 83%);
    }
  }

  .collapse {
    margin-top: -20px !important;
    padding: 20px 10px;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
    -webkit-backdrop-filter: blur(34px);
    backdrop-filter: blur(34px);
    border-radius: 7px;
    margin-bottom: 20px;

    P {
      margin-bottom: 0px;
    }

    ul {
      li {
        font-weight: 700;

        span {
          word-wrap: break-word;
          font-weight: 400;
          color: rgb(219, 224, 235);
        }
      }
    }
  }

  @media (min-width: 576px) {
    display: none;
  }
}
</style>
