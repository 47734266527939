<template>
  <div class="betListed">
    <div class="betListed-content">
      <div class="betListed-total">
        <p>
          <span class="dot"></span>
          {{ TruncateToDecimals2(totalPlayerGetProfit, '', 2) }}
          /
          {{ TruncateToDecimals2(totalPlayerBetClassic, '', 2) }}
          {{ $t('happy.player') }}
        </p>
        <p>{{ TruncateToDecimals2(totalAmountBetClassic.win, '', 2) }} Won</p>
        <p>
          {{ TruncateToDecimals2(totalAmountBetClassic.remain, '', 2) }} Remain
        </p>
        <p>{{ TruncateToDecimals2(totalAmountBetClassic.bet, '', 2) }} USD</p>
      </div>

      <div class="betListed-content-title">
        <p class="player">{{ $t('happy.player') }}</p>
        <p class="cashOut">Auto</p>
        <p class="cashOut">{{ $t('happy.cashOut') }}</p>
        <p class="amount">{{ $t('wallet.amount') }}</p>
        <p class="profit">{{ $t('exchange.profit') }}</p>
      </div>

      <template v-if="AllBetClassic && AllBetClassic.length > 0">
        <div id="betListed-content-list" class="betListed-content-list">
          <div
            class="betListed-content-item"
            v-for="(bet, idx) in AllBetClassic"
            :key="'bet' + idx"
          >
            <template v-if="bet">
              <p class="player">
                <img src="~@/assets/images/mockup/bet-list.png" alt="" />
                {{ bet.username | overflowed }}
              </p>
              <p
                class="cashOut"
              >
                {{
                    `${TruncateToDecimals2(+bet.payoutBefor, '', 2)}x`
                }}
              </p>
              <p
                class="cashOut"
                :class="bet.status !== 'PENDING' ? 'text-betting' : ''"
              >
                {{
                  bet.status === 'PENDING'
                    ? 'Wowing'
                    : bet.status === 'FAIL'
                    ? 'Bang'
                    : `${TruncateToDecimals2(+bet.payout, '', 2)}x`
                }}
              </p>
              <p class="amount">
                <img
                  :src="
                    require(`@/assets/images/currency/${bet.currency.toLowerCase()}.png`)
                  "
                  alt=""
                  class="currency"
                  v-if="bet.currency"
                />
                {{ TruncateToDecimals2(+bet.amountBet, '', 2) }} (
                {{ bet.currency }} )
              </p>
              <p
                class="profit"
                :class="
                  bet.status === 'SUCCESS'
                    ? 'text-win'
                    : bet.status === 'FAIL'
                    ? 'text-lose'
                    : ''
                "
              >
                <img
                  :src="
                    require(`@/assets/images/currency/${bet.currency.toLowerCase()}.png`)
                  "
                  alt=""
                  class="currency"
                  v-if="bet.status !== 'PENDING' && bet.currency"
                />
                {{
                  bet.status === 'PENDING'
                    ? 'Wowing'
                    : bet.status === 'FAIL'
                    ? TruncateToDecimals2(+bet.amountBet, '', 2)
                    : TruncateToDecimals2(
                        +bet.amountBet * (+bet.payout - 1),
                        '',
                        2
                      )
                }}
              </p>
            </template>
          </div>
        </div>
        <div class="betListed-content-button" v-if="false">
          <b-button class="btnNoStyle" @click="changeStatusShowMore">
            Show {{ !showMore ? 'More' : 'Less' }}
            <img
              src="~@/assets/images/icons/icon-down.png"
              alt=""
              v-if="!showMore"
            />
            <img src="~@/assets/images/icons/icon-up.png" alt="" v-else />
          </b-button>
        </div>
      </template>

      <template v-else>
        <p class="text-center mt-5 text-white font-weight-bold">Nothing here</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetListedClassicPageView',
  props: {
    AllUserBet: {
      type: Object,
      required: true,
    },
    MessageSocket: {
      type: Object,
    },
  },
  data() {
    return {
      showMore: false,
      maxHeight: 0,
    };
  },
  computed: {
    AllBetClassic() {
      if (this.AllUserBet.bet_classic) {
        const arrBetClassic = this.AllUserBet.bet_classic.filter(
          (bet) => bet.currency !== 'DBS' && !bet.isShow && bet.username !== 'dinhlong' && bet.username !== 'phattai68' && bet.username !== 'baonam' && bet.username !== 'bigwin1' && bet.username !== 'hami68' && bet.username !== 'locnguyen' && bet.username !== 'winwin9' && bet.username !== 'xuanvinh' && bet.username !== 'phuongthao68'
        );
        return arrBetClassic;
      }
      return [];
    },

    totalPlayerBetClassic() {
      let totalPlayer = 0;
      totalPlayer = this.AllBetClassic.length;
      return totalPlayer;
    },

    totalPlayerGetProfit() {
      let totalPlayer = 0;
      if (this.AllBetClassic.length > 0) {
        totalPlayer = this.AllBetClassic.reduce((total, item) => {
          if (item && item.status === 'SUCCESS') {
            return total + 1;
          }
          return total;
        }, 0);
      }
      return totalPlayer;
    },

    totalAmountBetClassic() {
      let total = {
        bet: 0,
        win: 0,
        remain: 0,
      };
      let cashedout = 0;

      this.AllBetClassic.forEach((item) => {
        if (item) {
          total.bet += item.amountBet;
          total.win += item.amountWin;
          total.remain += item.amountBet;
          if (item.status === 'SUCCESS') {
            cashedout += item.amountBet;
          }
        }
      });

      total.remain = total.bet - cashedout;
      return total;
    },
  },
  methods: {
    changeStatusShowMore() {
      const myDiv = document.getElementById('betListed-content-list');
      if (this.showMore) {
        myDiv.style.flexBasis = 'auto';
        this.showMore = false;
      } else {
        myDiv.style.flexBasis = myDiv.offsetHeight + 'px';
        this.showMore = true;
      }
      myDiv.scrollTop = 0;
    },
  },
  filters: {
    overflowed: (value) => {
      if (!value) return '';
      const preferLen = 8;
      if (typeof value === 'string' && value.length >= preferLen) {
        return value.slice(0, preferLen) + '...';
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.betListed {
  height: 100%;
  min-width: 320px;
  width: 100%;
  flex-grow: 1;
  .betListed-title {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }

  .betListed-content {
    background: linear-gradient(180deg, #757575 0%, rgba(1, 6, 15, 0.63) 100%);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    .betListed-total {
      background: rgba(49, 49, 49, 0.71);
      padding: 10px 15px;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
      p {
        color: white;
        font-weight: 500;
        margin-bottom: 0px;
        line-height: 100%;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .dot {
          width: 7px;
          height: 7px;
          background: gold;
          display: block;
          margin-right: 10px;
        }
      }
      @media (min-width: 992px) {
        margin-bottom: 16px;
      }
    }
    .betListed-content-title {
      display: flex;
      justify-content: space-between;
      color: #99a4b0;
      font-weight: bold;
      font-size: 14px;
      .cashOut {
        color: #99a4b0;
      }
      .profit {
        color: #99a4b0;
      }
    }
    .betListed-content-list {
      flex-shrink: 0;
      overflow: auto;
      flex-basis: 300px;
      .betListed-content-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0px;
        }
        p {
          color: white;
          font-size: 14px;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          img {
            width: 30px;
            margin-right: 7px;
            &.currency {
              width: 15px;
            }
          }
          &.cashOut {
            color: #8c989b;
          }
          &.profit {
            color: #8c989b;
          }
        }
      }
      @media (min-width: 992px) {
        flex-basis: 460px;
        .betListed-content-item {
          margin-bottom: 20px;
        }
      }
    }
    .betListed-content-button {
      margin-top: 20px;
      width: 100%;
      text-align: center;
      button {
        background: rgba(49, 49, 49, 0.71);
        color: #8c989b;
        img {
          margin-left: 5px;
        }
      }
    }
    .player {
      width: 30%;
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .cashOut {
      width: 20%;
      display: flex;
      justify-content: center;
      font-weight: 500;
      overflow: hidden;
    }
    .amount {
      width: 30%;
      display: flex;
      justify-content: center;
      font-weight: bold;
      overflow: hidden;
    }
    .profit {
      width: 15%;
      display: flex;
      justify-content: flex-end;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media (min-width: 992px) {
    width: auto;
  }
}
</style>
